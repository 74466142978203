import React from "react";
import SearchResultPage from "@templates/search-results-template";
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "@components/common/site/utils";

const SearchResult = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("sales", fullpath);

    return (
               <SearchResultPage locationname="/property/for-sale/" pcategorytype="residential" ptype="sales" ptypetag="for-sale" pstatustype="For Sale" fullpathone={fullpathone} fullpathname={fullpath}/>
    );
};

export default SearchResult;
